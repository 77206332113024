import WidthWrap from './util/WidthWrap';
import { Flex, VStack, Text, Box, HStack, Image, Spacer } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaTwitter, FaInstagram, FaDiscord } from 'react-icons/fa';

const social_links = [
    { title: FaTwitter, link: 'https://twitter.com/rizz_ai' },
    { title: 'Tiktok', link: 'https://www.tiktok.com/@rizz.ai' },
    { title: FaInstagram, link: 'https://www.instagram.com/rizz.ai.app/' },
    { title: FaDiscord, link: 'https://discord.gg/mrnTFEKnSy' },
];

const page_links = [
    { title: 'Home', link: '/' },
    { title: 'Terms', link: '/terms' },
    { title: 'Privacy', link: '/privacy' },
    { title: 'Support', link: '/support' },
];

const MiniLink = ({ link }) => {
    return (
        <Flex alignItems="flex-end">
            <a href={link.link}>
                <motion.div whileHover={{ y: -0.5, transition: { type: 'spring' } }}>
                    {typeof link.title === 'string' ? (
                        link.title === 'Tiktok' ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#929292"
                                height="20px"
                                width="20px"
                                viewBox="0 0 2859 3333"
                                shapeRendering="geometricPrecision"
                                textRendering="geometricPrecision"
                                imageRendering="optimizeQuality"
                                fillRule="evenodd"
                                clipRule="evenodd"
                            >
                                <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                            </svg>
                        ) : (
                            <Text color="white" fontWeight="semibold">
                                {link.title}
                            </Text>
                        )
                    ) : (
                        <link.title color="#929292" size="24px" />
                    )}
                </motion.div>
            </a>
        </Flex>
    );
};

const Footer = () => {
    return (
        <WidthWrap>
            <VStack w="full" padding={{ md: '12', base: '10' }} spacing="9">
                <VStack>
                    <Image
                        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=373367&theme=light&period=daily"
                        alt="Rizz&#0033; - The&#0032;world&#0039;s&#0032;most&#0032;powerful&#0032;AI&#0044;&#0032;built&#0032;into&#0032;your&#0032;keyboard | Product Hunt"
                        // style="width: 250px; height: 54px;"
                        // width="250"
                        // height="54"
                        onClick={() => {
                            window.open(
                                'https://www.producthunt.com/posts/rizz?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-rizz'
                            );
                        }}
                    />
                    <Spacer />
                    <Spacer />
                    {/* <Image
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=373367&theme=light"
            alt="Rizz&#0033; - The&#0032;world&#0039;s&#0032;most&#0032;powerful&#0032;AI&#0044;&#0032;built&#0032;into&#0032;your&#0032;keyboard | Product Hunt"
            // style="width: 250px; height: 54px;"
            // width="250"
            // height="54"
            onClick={() => {
              window.open(
                'https://www.producthunt.com/posts/rizz?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-rizz'
              );
            }}
          /> */}
                </VStack>
                <HStack spacing="6">
                    {page_links.map((link, id) => (
                        <MiniLink link={link} key={id} />
                    ))}
                </HStack>
                <HStack spacing="6">
                    {social_links.map((link, id) => (
                        <MiniLink link={link} key={id} />
                    ))}
                </HStack>
                <Box display={{ md: 'flex' }}>
                    <Text
                        textAlign="flex-end"
                        color="#929292"
                        fontSize={{ md: 'sm', sm: 'xs', base: 'xs' }}
                    >
                        © 2022-2024 Rizz
                    </Text>
                </Box>
            </VStack>
        </WidthWrap>
    );
};

export default Footer;
