import { Heading, Text, Box } from '@chakra-ui/react';

const Terms = () => {
    return (
        <Box
            color="white"
            paddingRight={{ base: '50px', md: '200px' }}
            paddingLeft={{ base: '50px', md: '200px' }}
            paddingTop="50px"
        >
            <Heading marginBottom="20px">Rizz Terms of Service</Heading>
            <Text marginTop="20px" marginBottom="20px">
                Rizz is a software application owned and operated by Nymeria Ltd an exempted company
                incorporated in the Cayman Islands under company number 403906. These Terms of
                Service (<b>"Terms"</b>) govern your use of Rizz and any related services or
                features. By accessing or using Rizz, you agree to be bound by these Terms. If you
                do not agree to these Terms, do not use Rizz.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                License to use Rizz
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                Subject to your compliance with these Terms, Nymeria Ltd grants you a limited,
                non-exclusive, non-transferable, revocable license to use Rizz for your personal,
                non-commercial use.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Acceptable use
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                You may use Rizz only for lawful purposes and in accordance with these Terms. You
                may not use Rizz to: (a) upload, post, transmit, or otherwise make available any
                content that is unlawful, fraudulent, deceptive, misleading, threatening, abusive,
                harassing, tortious, defamatory, vulgar, obscene, pornographic, libelous, or that
                violates any third party's intellectual property rights; (b) engage in any activity
                that is harmful to Nymeria Ltd or any third party; or (c) access or attempt to
                access any systems or networks that are not authorized to be accessed by you.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Intellectual property rights
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                Rizz and all content and materials included on or within Rizz, including but not
                limited to text, graphics, logos, images, software, and any other content
                (collectively, the <b>"Materials"</b>), are the property of Nymeria Ltd or its
                licensors and are protected by copyright and other intellectual property laws. You
                may not use any Materials for any commercial purpose or in any manner that infringes
                upon the rights of Nymeria Ltd or its licensors.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Disclaimer of warranties
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                Rizz is provided on an "as is" and "as available" basis. Nymeria Ltd makes no
                representations or warranties of any kind, express or implied, as to the operation
                of Rizz or the information, content, materials, or products included on Rizz.
                Nymeria Ltd will not be liable for any damages of any kind arising from the use of
                Rizz, including but not limited to direct, indirect, incidental, punitive, and
                consequential damages.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Termination
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                Nymeria Ltd reserves the right, in its sole discretion, to terminate or suspend your
                access to Rizz at any time and for any reason, without notice.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Governing law
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                These Terms and your use of Rizz will be governed by and construed in accordance
                with the laws of England & Wales, without giving effect to any principles of
                conflicts of law.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Dispute resolution
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                Any dispute arising out of or relating to these Terms or Rizz will be resolved
                through binding arbitration in accordance with the courts of England & Wales.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Entire agreement
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                These Terms constitute the entire agreement between you and Nymeria Ltd and
                supersede all prior or contemporaneous communications and proposals, whether oral or
                written.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Severability.
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                If any provision of these Terms is found to be invalid or unenforceable, that
                provision will be enforced to the maximum extent possible, and the remaining
                provisions of these Terms will remain in full force and effect.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Waiver
            </Heading>
            <Text marginTop="20px" marginBottom="20px">
                The failure of Nymeria Ltd to exercise or enforce any right or provision of these
                Terms will not constitute a waiver of such right or provision.
            </Text>

            <Text marginTop="20px" marginBottom="20px">
                If you have any questions about these Terms, please contact us at{' '}
                <a href="mailto:support@nymeria.app">support@nymeria.app</a>.
            </Text>
        </Box>
    );
};

export default Terms;
