import { Text, Box, VStack, HStack, Spacer } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import ReferralHero from './referralhero.webp';

const Referral = () => {
  const [name, setName] = useState();

  const get_referral_name = async () => {
    var myHeaders = new Headers();
    myHeaders.append('apikey', '69f2e327-36a9-4625-b02b-a6va8l6o0n54');
    myHeaders.append('Content-Type', 'application/json');

    const urlParams = new URLSearchParams(window.location.search);
    const referral_id = urlParams.get('referral');

    var raw = JSON.stringify({
      referral_id: referral_id,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(
      'https://8p1knntsyf.execute-api.us-west-1.amazonaws.com/Prod/referral/get',
      requestOptions
    )
      .then(result => result.json())
      .then(result => {
        setName(result.name);
      })
      .catch(error => console.log('error', error));
  };

  useEffect(() => {
    get_referral_name();
  }, []);

  return (
    <Box alignContent="center">
      <VStack marginTop="0px" paddingTop="0px">
        <img src={ReferralHero} alt="" width="370px" height="auto" />
        <VStack spacing="0px">
          <HStack>
            <Text
              fontSize="4xl"
              fontWeight="black"
              bgGradient="linear(to-l, #F89B13, #F3645B)"
              bgClip="text"
            >
              {name}
            </Text>
            <Text fontSize="4xl" color="white" fontWeight="black">
              invited
            </Text>
          </HStack>
          <Text
            as="span"
            display="inline"
            fontSize="4xl"
            color="white"
            fontWeight="black"
          >
            you to join Rizz!
          </Text>
        </VStack>
        <Spacer />
        <Spacer />
        <Spacer />
        <Box maxWidth="350px">
          <Text fontSize="lg" textAlign="center" color="white">
            Rizz! is an AI keyboard extension that writes your emails, messages,
            DMs, pickup lines, and more!
          </Text>
        </Box>
        <Spacer />
        <Spacer />
        <Spacer />
        <BrowserView>
          <Text
            fontSize="lg"
            textAlign="center"
            color="white"
            fontWeight="bold"
          >
            Please open this link on a mobile device to redeem your free words!
          </Text>
        </BrowserView>
        <MobileView>
          <Box
            as="button"
            fontSize="14px"
            fontWeight="bold"
            color="white"
            transition="all 0.2s cubic-bezier(.08, .52, .52, 1)"
            bgGradient="linear(to-l, #F89B13, #F3645B)"
            padding="15px"
            paddingLeft="60px"
            paddingRight="60px"
            verticalAlign="center"
            borderRadius="15px"
            onClick={() => {
              //navigate to /invite
              //pass referral_id as query param

              const urlParams = new URLSearchParams(window.location.search);
              const referral_id = urlParams.get('referral');
              console.log(referral_id);
              window.location.href = '/hooray?referral=' + referral_id;
            }}
          >
            Get 1,000 free words
          </Box>
        </MobileView>
      </VStack>
    </Box>
  );
};

export default Referral;
