import { Flex, Box, Heading, Text, Image } from '@chakra-ui/react';
import app_store from '../../assets/AppStore.png';
import logo from '../../assets/rizz.png';

const Landing = () => {
    return (
        <Flex
            bg="#181818"
            h="70%"
            w="100%"
            justifyContent={{ md: 'space-around', sm: '', base: '' }}
            alignItems={{ sm: 'center', md: '', base: 'center' }}
            flexDir={{ md: 'row', sm: 'column', base: 'column' }}
        >
            <Flex marginTop={{ base: '4' }} alignItems="center" flexDirection="column">
                <Box boxSize="3xs">
                    {' '}
                    <Image src={logo} alt="Rizz App" />{' '}
                </Box>

                <Heading
                    color="white"
                    w="100%"
                    fontWeight="bold"
                    textAlign={{ base: 'center', md: 'left', sm: 'center' }}
                    fontSize={{ md: '5xl', sm: '3xl', base: '3xl' }}
                >
                    Unleash the power of{' '}
                    <Box
                        as="span"
                        color="blue.50"
                        bgGradient="linear(127deg, #ee8a1b, #ffe683)"
                        bgClip="text"
                    >
                        infinite{' '}
                    </Box>
                    rizz!
                </Heading>

                <Text
                    color="white"
                    w="100%"
                    textAlign={{ base: 'center' }}
                    marginTop={{ base: '2', md: '2' }}
                    fontSize={{ base: 'medium', md: 'xl' }}
                    fontWeight="normal"
                >
                    The world's most powerful text AI, built into your keyboard
                </Text>
                <Box height="130px" width="200px" margin="10">
                    <Image
                        src={app_store}
                        color="white"
                        alt="App Store"
                        onClick={() => {
                            window.open('https://apps.apple.com/us/app/rizz/id1661403200');
                        }}
                    />
                    <button
                        style={{
                            background: 'linear-gradient(127deg, #ee8a1b, #FF7F50)',
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '1.2rem',
                            borderRadius: '6px',
                            borderWidth: '1.5px',
                            borderColor: 'lightgray',
                            marginTop: '20px',
                            width: '100%',
                            display: 'flex',
                            padding: '12px 8px',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={() => window.open('https://www.mywhiteboard.ai/?utm_source=rizz')}
                    >
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            version="1.1"
                            style={{ marginRight: '8px' }}
                            viewBox="0 0 16 16"
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M4.036 6.977l-2.29-3.966c1.466-1.835 3.722-3.012 6.254-3.012 2.929 0 5.489 1.574 6.883 3.922h-6.528c-0.117-0.010-0.236-0.016-0.356-0.016-1.904 0-3.509 1.307-3.964 3.071zM10.864 5.078h4.585c0.355 0.905 0.551 1.891 0.551 2.922 0 4.388-3.533 7.95-7.909 7.999l3.272-5.667c0.461-0.662 0.731-1.466 0.731-2.332 0-1.143-0.471-2.178-1.23-2.922zM5.094 8c0-1.603 1.304-2.906 2.906-2.906s2.906 1.304 2.906 2.906c0 1.602-1.304 2.906-2.906 2.906s-2.906-1.304-2.906-2.906zM9.097 11.944l-2.29 3.967c-3.852-0.576-6.806-3.899-6.806-7.911 0-1.425 0.373-2.763 1.026-3.922l3.266 5.657c0.654 1.392 2.070 2.359 3.707 2.359 0.38 0 0.747-0.052 1.097-0.149z"></path>
                        </svg>
                        Rizz for students
                    </button>
                </Box>
            </Flex>
        </Flex>
    );
};

export default Landing;
