import { Text, Box, VStack, HStack, Spacer, Image } from '@chakra-ui/react';
import { ReactComponent as HoorayEmoji } from './HoorayEmoji.svg';
import app_store from '../../assets/AppStore.png';

const Referral = () => {
    // get referral id from url, query parameter with key 'referral'
    const urlParams = new URLSearchParams(window.location.search);
    const referral_id = urlParams.get('referral');

    return (
        <Box>
            <VStack paddingTop="100px">
                <HoorayEmoji width="60px" height="auto" />
                <Text fontSize="5xl" fontWeight="black" color="white">
                    Hooray!
                </Text>
                <Box maxWidth="70%">
                    <HStack>
                        <Text fontSize="3xl" textAlign="center" color="white" fontWeight="semibold">
                            You just got
                        </Text>
                        <Text
                            fontSize="3xl"
                            textAlign="center"
                            bgGradient="linear(to-l, #F89B13, #F3645B)"
                            bgClip="text"
                            fontWeight="semibold"
                        >
                            1,000
                        </Text>
                    </HStack>
                    <Text fontSize="3xl" textAlign="center" color="white" fontWeight="semibold">
                        words, on us
                    </Text>
                </Box>
                <Spacer />
                <Spacer />
                <Spacer />
                <Box
                    as="button"
                    fontSize="14px"
                    fontWeight="bold"
                    color="white"
                    transition="all 0.2s cubic-bezier(.08, .52, .52, 1)"
                    bgGradient="linear(to-l, #F89B13, #F3645B)"
                    padding="15px"
                    paddingLeft="60px"
                    paddingRight="60px"
                    verticalAlign="center"
                    borderRadius="15px"
                    onClick={() => {
                        console.log(referral_id);
                        window.open(`rizz://rizzai.com/invite?referral=${referral_id}`);
                    }}
                >
                    Redeem my words
                </Box>
                <Spacer />
                <Spacer />
                <Text fontSize="sml" textAlign="center" color="#969696" fontWeight="regular">
                    Don't have Rizz! installed yet?
                </Text>
                <Spacer />
                <Spacer />
                <Box height="75px" width="200px" margin="10">
                    <Image
                        src={app_store}
                        color="white"
                        alt="App Store"
                        onClick={() => {
                            window.open('https://apps.apple.com/us/app/rizz/id1661403200');
                        }}
                    />
                </Box>
            </VStack>
        </Box>
    );
};

export default Referral;
