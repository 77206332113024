import './styles/App.css';

import Landing from './pages/Landing/Landing';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';
import Contact from './pages/Contact/Contact';
import Waitlist from './pages/Waitlist/Waitlist';
import Referral from './pages/Referral/Referral';
import Hooray from './pages/Referral/Hooray';

import { ChakraProvider } from '@chakra-ui/provider';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';

import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

import FullWrap from './components/util/FullWrap';
import { Helmet } from 'react-helmet';

import logo from './assets/rizz.png';
const breakpoints = createBreakpoints({
  xs: '25em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
});

// 2. Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  breakpoints,
};
// 3. extend the theme
const theme = extendTheme({ config });

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>Rizz</title>
        <meta property="og:title" content="Rizz" />
        <meta
          property="og:description"
          content="The easiest way to up your rizz!"
        />
        <meta property="og:image" content={logo} />
        <meta property="og:type" content="website" />
      </Helmet>
      <FullWrap bg="#242424">
        <Router>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/support" element={<Contact />} />
            <Route exact path="/waitlist" element={<Waitlist />} />
            <Route exact path="/invite" element={<Referral />} />
            <Route exact path="/hooray" element={<Hooray />} />
          </Routes>
          <Footer />
        </Router>
      </FullWrap>
    </ChakraProvider>
  );
};

export default App;
