import {
    Heading,
    Text,
    Box,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Button,
} from '@chakra-ui/react';

const Contact = () => {
    return (
        <Box
            color="white"
            paddingRight={{ base: '50px', md: '200px' }}
            paddingLeft={{ base: '50px', md: '200px' }}
            paddingTop="50px"
            width="100%"
        >
            <VStack>
                <Heading marginBottom="20px">Info & Support</Heading>
                <VStack alignContent="left" width="100%">
                    <Heading fontSize="2xl" marginBottom="20px" marginTop="20px" textAlign="left">
                        Troubleshooting
                    </Heading>
                    <Box width="85%" paddingBottom="20px">
                        <Accordion>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex="1" textAlign="left">
                                            How can I add the Rizz keyboard?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    Open Settings -> Keyboards -> Add New Keyboard -> Select
                                    Rizzboard -- Rizz! -> Allow Full Access so Rizz! can access the
                                    internet
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex="1" textAlign="left">
                                            I enabled everything and it still doesn't work?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    After providing Rizz! keyboard with access, please quit and
                                    restart settings and any other app you are using with Rizz!
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex="1" textAlign="left">
                                            Why does Rizz need full access?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    In order to provide you with the full functionality of our
                                    keyboard, it is necessary for the extension to have full access
                                    to your device so it can access the internet. We understand that
                                    granting full access to your device can be a sensitive issue,
                                    and we want to assure you that we take the security and privacy
                                    of your data very seriously. We use industry-standard encryption
                                    techniques to ensure that your data is kept safe and secure at
                                    all times.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex="1" textAlign="left">
                                            How is my data being stored?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    We take the security and privacy of your data very seriously.
                                    That's why we want to assure you that your data is being stored
                                    in a secure and encrypted manner. This means that it is
                                    protected against unauthorized access or manipulation. We use
                                    industry-standard encryption techniques to ensure that your data
                                    is kept safe and secure at all times. If you have any further
                                    questions or concerns about how your data is being stored,
                                    please don't hesitate to reach out to us. We are always happy to
                                    help.
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                    <Heading
                        fontSize="2xl"
                        marginBottom="20px"
                        marginTop="20px"
                        textAlign="center"
                        paddingBottom="20px"
                    >
                        Question still not answered?
                    </Heading>
                    <form action="mailto:rizzappai@gmail.com">
                        <Button type="submit" colorScheme="orange">
                            <Text color="white">Email Us</Text>
                        </Button>
                    </form>
                </VStack>
            </VStack>
        </Box>
    );
};

export default Contact;
