import { Heading, Text, Box } from '@chakra-ui/react';

const Privacy = () => {
    return (
        <Box
            color="white"
            paddingRight={{ base: '50px', md: '200px' }}
            paddingLeft={{ base: '50px', md: '200px' }}
            paddingTop="50px"
        >
            <Heading marginBottom="20px">Rizz Privacy Policy</Heading>

            <Text>
                Thank you for using Rizz!, the AI keyboard extension that uses OpenAI's GPT-3 model
                to generate texts from mobile keyboard input.
            </Text>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                Information we collect
            </Heading>

            <Text marginTop="10px" marginBottom="10px">
                Your privacy is important to us, and we want to be transparent about the data we
                collect and how we use it.
            </Text>

            <ul>
                <li>
                    Rizz! does not collect any personal information from users, such as their name,
                    email address, or phone number.
                </li>
                <li>
                    We do not collect any sensitive personal information from users, such as
                    financial information or information about their health.
                </li>
            </ul>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                How we use your information
            </Heading>

            <Text marginTop="10px" marginBottom="10px">
                The only third party that Rizz! shares user input with is OpenAI's GPT-3 model.
            </Text>

            <ul>
                <li>
                    We will never share user data or third party information with advertisers, and
                    we will never sell user data. Rizz! does not run ads, Rizz! will never run ads.
                </li>
                <li>
                    Rizz! does not use any information it collects from users to provide
                    personalization or for any other purpose.
                </li>
                <li>
                    We use industry standard encryption to store user data and take the security of
                    user data seriously.
                </li>
            </ul>

            <Heading fontSize="2xl" marginBottom="20px" marginTop="20px">
                How we secure your data
            </Heading>

            <Text marginTop="10px" marginBottom="10px">
                If you have any questions about our privacy policy or the data we collect, please do
                not hesitate to contact us.
            </Text>
            <Heading fontSize="2xl" marginTop="20px" marginBottom="20px">
                Changes to this Policy
            </Heading>
            <Text marginTop="10px" marginBottom="10px">
                We may update this Policy from time to time to reflect changes to our practices or
                for other operational, legal, or regulatory reasons. We will post any updates on
                this page and encourage you to review this Policy regularly.
            </Text>
            <Heading fontSize="2xl" marginTop="20px" marginBottom="20px">
                Contact us
            </Heading>
            <Text marginTop="10px" marginBottom="10px">
                If you have any questions about this Policy or our handling of your information,
                please contact us at <a href="mailto:support@nymeria.app">support@nymeria.app</a>
            </Text>
        </Box>
    );
};

export default Privacy;
